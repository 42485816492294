import '../styles/globals.css';
import '../styles/nprogress.css';
import '../styles/calendar.css';
import 'react-dates/initialize';

import { appWithTranslation } from 'next-i18next';
import Router from 'next/router';
import nProgress from 'nprogress';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import 'moment/locale/de';

import Head from 'next/head';

import Header from '@/components/globals/header';
import Cookies from 'js-cookie';
import animations from '../lib/animations';
import { GlobalStateProvider } from '../lib/app-server-context';
import Footer from '../components/globals/footer';

const ReactTooltip = dynamic(() => import('react-tooltip'), { ssr: false });

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const DynamicComponentWithNoSSR = dynamic(() => import('../lib/consent'), { ssr: false });

function uuid() {
  let uuidValue = '';
  let k;
  let randomValue;
  // eslint-disable-next-line no-plusplus
  for (k = 0; k < 32; k++) {
    // eslint-disable-next-line no-bitwise
    randomValue = Math.random() * 16 | 0;

    if (k === 8 || k === 12 || k === 16 || k === 20) {
      uuidValue += '-';
    }
    // eslint-disable-next-line no-bitwise,no-mixed-operators,no-nested-ternary
    uuidValue += (k === 12 ? 4 : (k === 16 ? (randomValue & 3 | 8) : randomValue)).toString(16);
  }
  return uuidValue;
}

function App({
  Component, pageProps, router,
}) {
  const startIndex = 2;
  const [animation] = useState(animations[startIndex]);
  const [exitBefore] = useState(false);

  useEffect(() => {
    if (!Cookies.get('cid')) {
      Cookies.set('cid', uuid(), {
        // expires: 30,
        path: '/',
        httpOnly: false,
      });
    }

    const referer = router.query.r || router.query.ref || router.query.referer || router.query.referrer;

    if (!Cookies.get('ref') && referer) {
      Cookies.set('ref', referer, {
        expires: 30,
        path: '/',
        httpOnly: false,
      });
    } else if (!Cookies.get('ref')) {
      Cookies.set('ref', 'br24', {
        expires: 30,
        path: '/',
        httpOnly: false,
      });
    }
  }, []);

  return (
    <GlobalStateProvider>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      </Head>
      <Header />

      <Component {...pageProps} />

      <ReactTooltip />
      <Footer />

      <DynamicComponentWithNoSSR />

      <Script
        id="analytics"
        data-cookiecategory="analytics"
        strategy="afterInteractive"
        type="text/plain"
      >
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_TAG}');`}
      </Script>

    </GlobalStateProvider>
  );
}

export default appWithTranslation(App);
